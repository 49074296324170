export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  components: {
    hotCardSocialProof: () => import('~/components/Molecules/CardSocialProof/CardSocialProof.vue'),
  },
}
